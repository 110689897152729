import React, { useState } from 'react';
import AxiosInterceptor from "../../../AxiosInterceptor";
import './DownloadButton.scss';
import urls from 'config/urls';
import Icon from 'components/Icon/Icon';
import { Spinner } from 'components/Spinner/Spinner';
import { Toast } from 'components/Toast/Toast';
import { Button } from '@mui/material';

type DownloadButtonProps = {
    title: string;
    fileName: string;
    downloadPath: string;
    icon?: string;
    onDownloadSuccess?: () => void;
};

const DownloadButton: React.FC<DownloadButtonProps> = ({ downloadPath, fileName, title = 'Descargar', icon = 'download', onDownloadSuccess }) => {
    const [processing, setProcessing] = useState(false);
    const [toastIsOpen, setToastIsOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastSeverity, setToastSeverity] = useState("success");

    // Función para manejar la descarga
    const handleDownload = async () => {
        try {
            setProcessing(true);

            // Realizar la solicitud al servidor para descargar el archivo
            const res = await AxiosInterceptor.post(`${urls.server}/api/${downloadPath}`, {
                responseType: 'blob',  // Necesario para manejar la descarga de archivos
            });

            // Crear un enlace para descargar el archivo
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;

            // Definir el nombre del archivo descargado
            link.setAttribute('download', fileName);  // Puedes ajustar este nombre dinámicamente si es necesario
            document.body.appendChild(link);
            link.click();

            // Eliminar el enlace después de la descarga
            link.parentNode?.removeChild(link);

            setProcessing(false);
            setToastMessage(`Archivo descargado correctamente`);
            setToastIsOpen(true);
            setToastSeverity("success");

            if (onDownloadSuccess) {
                onDownloadSuccess();
            }
        } catch (error: any) {
            setProcessing(false);
            setToastMessage(error.toString());
            setToastIsOpen(true);
            setToastSeverity("error");
        }
    }

    return (
        <>
            <Button
                className="download-button-container"
                color="inherit"
                onClick={handleDownload}  // Llama a la función de descarga
                aria-label="download"
                disabled={processing}
            >
                {processing ? <Spinner /> : null}
                {title}
                <Icon name={icon} />
            </Button>
            <Toast
                isOpen={toastIsOpen}
                onClose={() => setToastIsOpen(false)}
                message={toastMessage}
                severity={toastSeverity}
            />
        </>
    );
};

export default DownloadButton;
